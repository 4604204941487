import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.header`
  background-color: #1a237e;
  padding: ${(props) => props.theme.spacing.medium} 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: ${(props) =>
    props.isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none"};
  transition: box-shadow 0.3s ease;
`;

const HeaderContent = styled.div`
  ${(props) => props.theme.container}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  ${(props) => props.theme.typography.h4}
  color: #ffffff;
  margin: 0;
`;

const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: ${(props) => props.theme.spacing.large};

      a {
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #00bcd4;
        }
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    ul {
      flex-direction: column;
      align-items: center;

      li {
        margin: ${(props) => props.theme.spacing.small} 0;
      }
    }
  }
`;

function Header() {
  const [isScrolled, setIsScrolled] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContent>
        <Logo>Compact SaaS</Logo>
        <Nav>
          <ul>
            <li>
              <button 
                style={{ 
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'default',
                  opacity: 0.7,
                  padding: 0,
                  font: 'inherit'
                }}
              >
                Services (Coming Soon)
              </button>
            </li>
            <li>
              <button 
                style={{ 
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'default',
                  opacity: 0.7,
                  padding: 0,
                  font: 'inherit'
                }}
              >
                Benefits (Coming Soon)
              </button>
            </li>
            <li>
              <button 
                style={{ 
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'default',
                  opacity: 0.7,
                  padding: 0,
                  font: 'inherit'
                }}
              >
                How It Works (Coming Soon)
              </button>
            </li>
            <li>
              <button 
                style={{ 
                  background: 'none',
                  border: 'none',
                  color: 'white',
                  cursor: 'default',
                  opacity: 0.7,
                  padding: 0,
                  font: 'inherit'
                }}
              >
                About (Coming Soon)
              </button>
            </li>
          </ul>
        </Nav>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;
